/* SPADA UI Framework v.0.1 */

/* Fonts */
@font-face {
    font-family: 'montserratbold';
    src: local('montserrat-bold'), url(./fonts/montserrat-bold.woff2) format('woff2'),
         local('montserrat-bold'), url(./fonts/montserrat-bold.woff) format('woff'),
         local('montserrat-bold'), url(./fonts/montserrat-bold.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratextrabold';
    src: local('montserrat-bold'), url(./fonts/montserrat-extrabold.woff2) format('woff2'),
         local('montserrat-bold'), url(./fonts/montserrat-extrabold.woff) format('woff'),
         local('montserrat-bold'), url(./fonts/montserrat-extrabold.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: local('montserrat-bold'), url(./fonts/montserrat-regular.woff2) format('woff2'),
         local('montserrat-bold'), url(./fonts/montserrat-regular.woff) format('woff'),
         local('montserrat-bold'), url(./fonts/montserrat-regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsbold';
    src: local('poppinsbold'), url(./fonts/poppins-bold-webfont.eot);
    src: local('poppinsbold'), url(./fonts/poppins-bold-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.woff2) format('woff2'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.woff) format('woff'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.ttf) format('truetype'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.svg#poppinsbold) format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinssemibold';
    src: local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.eot);
    src: local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.woff2) format('woff2'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.woff) format('woff'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.ttf) format('truetype'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.svg#poppinssemibold) format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsregular';
    src: local('poppinsregular'), url(./fonts/poppins-regular-webfont.eot);
    src: local('poppinsregular'), url(./fonts/poppins-regular-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.woff2) format('woff2'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.woff) format('woff'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.ttf) format('truetype'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.svg#poppinsregular) format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Presets */
.text-size-up {
	font-size: 1.2em; 
}
.text-size-down {
	font-size: 0.9em;
}
.text-bold {
	font-family: 'montserratbold';
}
.text-extrabold {
	font-family: 'montserratextrabold';
}
.text-crossed {
	text-decoration: line-through;
}
strong,
.bold {
	font-weight: 600;
}
.mobile-visible {
	display: none;
	visibility: hidden;
}
.mobile-hidden {
	width: auto !important;
	display: block;
	visibility: visible;
}
.text-italic {
	font-style: italic !important;
}
.text-color-blue {
	color: #039be6 !important;
}
.text-color-red {
	color: #ff4040 !important;
}
.text-color-yellow {
	color: #ffae00 !important;
}
.text-color-green {
	color: #0d9488 !important;
}
.capitalize {
	text-transform: capitalize;
}
.uppercase {
	text-transform: uppercase;
}
.lowercase {
	text-transform: lowercase;
}
.underlined {
	text-decoration: underline;
}
.align-left {
	text-align: left !important;
}
.align-center {
	text-align: center !important;
}
.align-right {
	text-align: right !important;
}
.wrap { 
	word-wrap: break-word;
	overflow-wrap: break-word;
}
.width-30 {
	width: 30%;
}
.width-50 {
	width: 50%;
}
.width-60 {
	width: 60%;
}
.width-75 {
	width: 75%;
}
.full-width {
	width: 100% !important;
}
.full-height {
	min-height: 100vh !important;
}
.has-padding {
	padding: 20px !important;
}
.has-padding-top {
	padding-top: 20px !important;
}
.has-padding-right {
	padding-right: 20px !important;
}
.has-padding-bottom {
	padding-bottom: 20px !important;
}
.has-padding-left {
	padding-left: 20px !important;
}
.has-padding-horizontal {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.has-padding-vertical {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}
.no-padding {
	padding-top: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
}
.has-margin {
	margin: 30px !important;
}
.has-margin-top {
	margin-top: 20px !important;
}
.has-margin-right {
	margin-right: 20px !important;
}
.has-margin-bottom {
	margin-bottom: 20px !important;
}
.has-margin-left {
	margin-left: 20px !important;
}
.has-margin-horizontal {
	margin-left: 20px !important;
	margin-right: 20px !important;
}
.has-margin-vertical {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}
.no-margin {
	margin-top: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
}
.no-line-height {
	line-height: 1 !important;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.clear {
	clear: both;
}
.group:after {
	content: "";
	display: table;
	clear: both;
}
.hidden {
	display: none !important;
}
.center {
	margin: 0 auto;
}
.absolute-center {
	display: block;
	width: 80%;
	height: auto;
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.no-border {
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	border-left: none !important;
}
.has-border-top {
	border-top: 1px solid #ddd !important;
}
.has-border-right {
	border-right: 1px solid #ddd !important;
}
.has-border-bottom {
	border-bottom: 1px solid #ddd !important;
}
.has-border-left {
	border-left: 1px solid #ddd !important;
}
.rounded {
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
	overflow: hidden;
}
.rounded-small {
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	overflow: hidden;
}
.rounded-medium {
	border-radius: 10px !important;
	-webkit-border-radius: 10px !important;
	overflow: hidden;
}
.rounded-large {
	border-radius: 15px !important;
	-webkit-border-radius: 15px !important;
	overflow: hidden;
}
.desaturate {
    filter: saturate(0%);
    opacity: 0.7;
}
.clickable {
	cursor: pointer;
}
.animate-blink {
	animation: blink 1.2s normal infinite linear;
}
.animate-border-blink {
	animation: border-blink 1.5s normal infinite linear;
}
.button-red {
	color: #fff !important;
	background: #ff4040 !important;
	border-color: #ff4040 !important;
	cursor: pointer;
}

/* Scrollbar */

::-webkit-scrollbar {
 	width: 7px;
}

::-webkit-scrollbar-track {
	background: #ddd;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}

::-webkit-scrollbar-thumb {
	background: #31529a;
	border-radius: 7px;
	-webkit-border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
	background: #1e88e5;
}

/* Global Style */
body {
	width: 100%;
	font-weight: 400;
	font-style: normal;
	font-family: 'montserratregular';
	line-height: 1.3;
}

header {
	padding: 5px 0px;
}
h1,
h2,
h3 {
	height: auto !important;
	font-style: normal;
	margin-top: 0;
	margin-bottom: 15px;
}
h4,
h5 {
	height: auto !important;
	font-weight: normal !important;
	margin-top: 0;
	margin-bottom: 15px;
}
a {
	font-weight: normal !important;
}
p,
img {
	max-width: 100% !important;
	margin-top: 0px;
}
img.grayscale {
  filter: grayscale(100%);
}
label,
form label {
	font-size: 0.9em !important;
	color: #555;
	margin-left: 0 !important;
}
.form-label,
.label {
	font-size: 0.9em !important;
	color: #555;
	margin-bottom: 7px;
}
input,
select {
	font-weight: normal !important;
	border: 1px solid #dedede;
	padding: 8px !important;
}
textarea {
	width: 100% !important;
	min-height: 80px !important;
	font-size: 0.9em !important;
	font-weight: normal !important;
}
button {
	min-height: auto !important;
	text-align: center !important;
}
#logo img {
	width: 150px;
}
.panel-action button {
	background-color: #31529a !important;
}
.main-wrapper {
	min-height: 100vh !important;
	position: relative;
	overflow-x: hidden;
}
.container {
	width: 100%;
	min-height: 100vh !important;
	position: relative;
}
.page-wrapper {
	position: relative;
}
.page-container {
	position: relative;
}
.styled-anchor > span > span {
	width: 8px !important;
}
.full-height {
	min-height: 100vh !important;
}
.tags {
	color: #999;
	border: 1px solid #999;
	padding: 2px 8px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.section-heading {
	font-family: 'montserratbold';
}
.text-highlight {
	color: #32529a;
	background-color: #ffca28;
	padding: 2px 10px;
}
#hero {
	padding: 50px 0px 0px 0px !important;
}
.hero-tagging span {
	margin-bottom: 15px;
	display: inline-block;
}
#intro,
#features,
#warranty,
#pricing {
	background-color: #fff;
}
.step-item {
	width: 60px;
	height: 51px;
	font-family: 'montserratbold';
	font-size: 1.8em !important;
	color: #31529a;
	background-color: #fdb915;
}
#modules,
#requirement {
	color: #fff;
	background-color: #446cc3;
}
.countdown-timer > div {
	width: 25px !important;
	line-height: 35px !important;
	text-align: center;
	background-color: #eee;
	margin: 0 3px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	position: relative;
}
.countdown-timer > div:first-of-type {
	margin-left: 0px;
}
.countdown-timer > div > div {
	width: 25px !important;
}
.faq-list > div:nth-of-type(odd) {
	border: 1px solid #dedede;
	border-radius: 7px;
	-webkit-border-radius: 7px;
}
.faq-list-item {
	padding: 20px 10px 20px 25px;
}
.faq-list-item h4 {
	margin: 0px;
}
.faq-list-item-content {
	padding-left: 63px;
	padding-bottom: 20px;
	padding-right: 63px;
}
.offer {
	color: #fff;
	background-color: #D32F2F;
	padding: 2px 8px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.checkout-steps a {
	background: #ddd;
	padding: 5px 10px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
}
.checkout-steps a.active {
	color: #31529a;
	background: #fff;
}
.data-table .table-header {
	border-bottom: 1px solid #dedede;
	padding-bottom: 10px;
}
.data-table .table-footer {
	border-top: 1px solid #eaeaea;
	padding-top: 10px;
}
.order-information-row {
	background-color: #fff8e1;
	padding: 10px;
}
.select-payment {
	border-top: 1px solid #eaeaea;
	padding-top: 15px;
}
.payment-option {
	border: 1px solid #dedede;
	padding: 15px;
	border-radius: 7px;
	-webkit-border-radius: 7px;
}
#copyright {
	text-align: center !important;
}
.animate-blink {
	animation: blink 1s linear infinite;
}
@keyframes blink {
	0%{opacity: 0;}
	33%{opacity: .35;}
	66%{opacity: .65;}
	100%{opacity: 1;}
}

@media screen and (min-width: 1100px) {
	.page-container {
		width: 1248px !important;
		max-width: 1248px !important;
		margin: 0 auto;
		position: relative;
	}
}

@media screen and (min-width: 769px) {
	#header {
		padding: 20px 0;
	}
	.section {
		padding: 100px 0;
	}
	.section-heading {
		font-size: 2em !important;
		line-height: 1.3 !important;
	}
	.hero-image {
		padding: 0px 14%;
	}
	.step-item-content {
		width: 100%;
		max-width: unset !important;
	}
	.highlight-image {
		padding-left: 15%;
	}
}

@media screen and (max-width: 768px) {
	.mobile-visible {
		display: block;
		visibility: visible;
	}
	.mobile-hidden {
		width: 0px !important;
		display: none;
		visibility: hidden;
	}
	.width-30 {
		width: 50%;
	}
	.width-50 {
		width: 70%;
	}
	.width-60 {
		width: 80%;
	}
	.width-75 {
		width: 90%;
	}
	h4,
	h5 {
		margin-bottom: 8px;
	}
	#header {
		padding: 10px 0;
	}
	.section {
		padding: 60px 0;
	}
	.page-container {
		padding: 0 10px;
	}
	.section-heading {
		font-size: 1.3em !important;
		line-height: 1.3 !important;
	}
	.hero-image {
		padding: 0px 15%;
	}
	#hero {
		text-align: center !important;
	}
	.intro-image {
		padding: 0px 10%;
	}
	.step-item-content {
		width: 85%;
		max-width: unset !important;
	}
	.highlight-image {
		padding: 0px 15%;
	}
}

